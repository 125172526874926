import React from "react";
import "./TokenItem.css";
import {Link, NavLink} from "react-router-dom";
import default_avater from '../../../assets/img/default_avater.png'

interface CardProps {
    data?: any;
}

interface PriceChartProps {
    data: any,
    open: boolean
}

export function TokenItem({ data, open }: PriceChartProps) {
  return (
    <div className={"pay_card_box"}>
      <div className="pay_card_inline">
        <div className="box_logo">
          <img className="rounded-full" src={open? data["icon"] : default_avater} alt="" />
        </div>
        <p className="text">{data["name"]}</p>
        <p className="text">{data["symboy"]}</p>

          {
              data["id"] === -10 ?
              <a className="do-btn"  href="https://newcryptospace.com/#/airdrop/vote" >
                  <div className={'common_btn'}>{"Enter"}</div>
              </a>
              :
              <Link className="do-btn"  to={open? "/i/" + data["id"] : "#"} >
                  <div className={open ? 'common_btn' : 'disable_btn'}>{open ? "Join" : "Coming soon"}</div>
              </Link>
          }


      </div>
    </div>
  );
};

export default TokenItem;
