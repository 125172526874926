import {
    cPoint,
    Rubbings,
    standard,
    x20_BillGates,
    x20_CryptoHayes,
    x20_cz_binance,
    x20_elonmusk,
    x20_VitalikButerin
} from "../assets";

export const X20_FACTORY_PUB ='0x4A19688429f8844cAA340144aa6f3E2a52E34a22'
export const X20_FACTORY ='0x4A19688429f8844cAA340144aa6f3E2a52E34a22'
export const INVITER_ADDR = "INVITER_ADDR";
export const TEST_CRYPTO_TOKEN ='0x9842989969687f7d249d01Cae1D2ff6b7b6b6D35'

//ALL，V神，奥特曼，赵长鹏，Arthur Hayes（Bitmex创始人，黑人，喊单王，SUSHI就是他喊起来的）
export const x20PubTokenList = [
    {
        id:0,
        name:"SOCIAL",
        symboy:"@SOCIAL(不通缩)",
        token: "0xc38327E970AE1E7958cF1C4096af0CA95Ff02E78",
        icon: "https://newcryptospace.com/favicon.png",
    },
    {
        id:1,
        name:"vitalik.eth",
        symboy:"@VitalikButerin(通缩1%)",
        token: "0x9148e52f6513dd61b7538b99aa71a2ef25729a6d",
        icon: x20_VitalikButerin,
    },
    {
        id:2,
        name:"CZ 🔶 Binance",
        symboy:"@cz_binance",
        token: "0xcc3b5b730b7f8a35845317d634dd55e9ff2c3f13",
        icon: x20_cz_binance,
    },
    {
        id:3,
        name:"Arthur Hayes",
        symboy:"@CryptoHayes",
        token: "0x7d1b33b00b7960d950c00b9183669bab3818b7b1",
        icon: x20_CryptoHayes,
    },
    {
        id:4,
        name:"Bill Gates",
        symboy:"@BillGates",
        token: "0x7d1b33b00b7960d950c00b9183669bab3818b7b1",
        icon: x20_BillGates,
    },
    {
        id:5,
        name:"Elon Musk",
        symboy:"@elonmusk",
        token: "0x7d1b33b00b7960d950c00b9183669bab3818b7b1",
        icon: x20_elonmusk,
    },
];

export const x20CpointTokenList = [
    {
        id:-10,
        name:"C Scores",
        symboy:"Cpoints (Part for eligible address)",
        token: "0x7f5e4a77d746ef220156803832c52a0cc11e44be",
        icon: "https://pbs.twimg.com/profile_images/1748153260203229184/sXJIGMBk_400x400.jpg",
    },
    {
        id:0,
        name:"",
        symboy:"",
        token: "",
        icon: "",
    },
    {
        id:0,
        name:"",
        symboy:"",
        token: "",
        icon: "",
    },

];


export const DappsList = [
    {
        style:1,
        name:"STANDARD",
        desc:"STANDARD app",
        url: "/Standard",
        icon: standard,
        open: true
    },
    {
        style:1,
        name:"Cpoints",
        desc:"Cpoints",
        url: "/airdrop/vote",
        icon: cPoint,
        open: true
    },
    {
        style:1,
        name:"Rubbings",
        desc:"Rubbings app",
        url: "/Rubbings/eth",
        icon: Rubbings,
        open: true
    },

    {
        style:0,
        name:"Coming soon",
        desc:"",
        url: "",
        icon: "",
        open: false
    },

];
