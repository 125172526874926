import {initializeAnalytics, sendAnalyticsEvent, user} from 'components/AmplitudeAnalytics'
import {CUSTOM_USER_PROPERTIES, EventName, PageName} from 'components/AmplitudeAnalytics/constants'
import {Trace} from 'components/AmplitudeAnalytics/Trace'
import Loader from 'components/Loader'
import TopLevelModals from 'components/TopLevelModals'
import {useFeatureFlagsIsLoaded} from 'featureFlags'
import {usePhase0Flag} from 'featureFlags/flags/phase0'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import {ReactNode, Suspense, useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import {useIsDarkMode} from 'state/user/hooks'
import styled from 'styled-components/macro'
import {SpinnerSVG} from 'theme/components'
import {getBrowser} from 'utils/browser'
import {Currency, TradeType} from '@uniswap/sdk-core'
import {TradeState} from 'state/routing/types'

import {useAnalyticsReporter} from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import {useIsExpertMode} from '../state/user/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Admin from '../views/manage/Admin'
import VoteAdmin from '../views/manage/VoteAdmin'
import EnsAdmin from '../views/manage/EnsAdmin'
import EnsSet from '../views/EnsSet'
import Airdrop from '../views/Airdrop'
import AirdropPuls from '../views/AirdropPuls'
import VoteAirdrop from '../views/VoteAirdrop'
import Ens from '../views/Ens'
import Home from '../views/Home'
import Show from '../views/Show'
import Invite from '../views/Invite'
import Footer from '../components/Footer'


import DidHome from '../views/com/DidHome'
import ComHome from '../views/com/ComHome'
import Standard from '../views/com/Standard/Standard'
import MetaXPage from '../views/EthLand/MetaXPage'
import EthHome from '../views/EthLand/EthHome'
import TweetTotal from '../views/Tweet/TweetTotal'
import TweetInfo from '../views/Tweet/TweetInfo'

import {InterfaceTrade} from '../state/routing/types'
import {ISDID_SYSTEM} from "../constants/addresses";
import PubTokenHome from "../views/X20Pub/PubTokenHome/PubTokenHome";
import PubTokenInfo from "../views/X20Pub/PubTokenInfo/PubTokenInfo";
import MyTokenList from "../views/X20/MyTokenList/MyTokenList";
import TokenInfo from "../views/X20/TokenInfo/TokenInfo";
import PubCpointHome from "../views/X20Pub/PubCpointHome/PubCpointHome";
import TwtFooter from 'components/Footer/TwtFooter'
import PubTokenLog from "../views/X20Pub/PubTokenInfo/PubTokenLog";
import PubProfile from "../views/X20Pub/PubProfile/PubProfile";
import Rubbings from "../views/EthLand/Rubbings/Rubbings";
import Collections from "../views/EthLand/Rubbings/Collections";
import RubbingsInfo from "../views/EthLand/Rubbings/RubbingsInfo";
import AppsHome from "../apps/AppsHome/AppsHome";
import CrossChina from "../apps/CrossChina/CrossChina";
import BtcInscribe from "../views/com/BtcInscribe/BtcInscribe";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-family: 'Franklin Gothic Medium', PingFang SC, PingFang SC-Medium !important;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 0px 0px 0px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function getCurrentPageFromLocation(locationPathname: string): PageName | undefined {
  switch (locationPathname) {
    case '/swap':
      return PageName.SWAP_PAGE
    case '/vote':
      return PageName.VOTE_PAGE
    case '/pool':
      return PageName.POOL_PAGE
    case '/explore':
      return PageName.EXPLORE_PAGE
    default:
      return undefined
  }
}

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
)

export function getIsValidSwapQuote(
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && (tradeState === TradeState.VALID || tradeState === TradeState.SYNCING)
}

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded()
  const phase0Flag = usePhase0Flag()

  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const isDarkMode = useIsDarkMode()
  const isExpertMode = useIsExpertMode()

  useAnalyticsReporter()
  initializeAnalytics()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    // TODO(zzmp): add web vitals event properties to app loaded event.
    sendAnalyticsEvent(EventName.APP_LOADED)
    user.set(CUSTOM_USER_PROPERTIES.BROWSER, getBrowser())
    user.set(CUSTOM_USER_PROPERTIES.SCREEN_RESOLUTION_HEIGHT, window.screen.height)
    user.set(CUSTOM_USER_PROPERTIES.SCREEN_RESOLUTION_WIDTH, window.screen.width)
  }, [])

  useEffect(() => {
    user.set(CUSTOM_USER_PROPERTIES.DARK_MODE, isDarkMode)
  }, [isDarkMode])

  useEffect(() => {
    user.set(CUSTOM_USER_PROPERTIES.EXPERT_MODE, isExpertMode)
  }, [isExpertMode])

  const PLANT_TYPE = process.env.REACT_APP_PLANT_TYPE;

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <ApeModeQueryParamReader />
      <AppWrapper className={"mainBody"}>
        <Trace page={currentPage}>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Polling />
            <TopLevelModals />
            <Suspense fallback={<Loader />}>
              {isLoaded ? (
                  PLANT_TYPE === 'X20_PUB' ?
                      <Routes>
                        <Route element={<PubTokenHome />} path="/" />
                        <Route element={<PubTokenHome />} path="/:inviter" />
                        <Route element={<PubTokenInfo />} path="/i/:token"/>
                        <Route element={<PubTokenLog />} path="/log/:token"/>
                        <Route element={<PubCpointHome />} path="/c"/>
                        <Route element={<PubProfile />} path="/profile"/>

                         {/*大众版本*/}
                        <Route path="/tweetTotal" element={<TweetTotal />} />
                        <Route path="/tweetTotal/:account" element={<TweetTotal />} />
                        <Route path="/x/:twUserName" element={<TokenInfo />} />
                        {/*<Route path="/profile" element={<MyTokenList />} />*/}

                      </Routes>
                  : PLANT_TYPE === 'VOTE_ENS' ?
                      <Routes>

                        <Route path="/apps" element={<AppsHome />} />
                        <Route path="/CrossChina" element={<CrossChina />} />

                        <Route path="/" element={<ComHome />} />
                        <Route path="/:account" element={<ComHome />} />
                        <Route path="/Standard" element={<Standard />} />
                        <Route path="/Collections" element={<Collections />} />
                        <Route path="/Rubbings/:id" element={<Rubbings />} />
                        <Route path="/RubbingsInfo/:id" element={<RubbingsInfo />} />
                        <Route path="/MyLands" element={<MetaXPage />} />
                        <Route path="/btcInscribe" element={<BtcInscribe />} />
                        {/*<Route path="/mintToken" element={<MintToken />} />*/}

                        <Route path="/airdrop/points" element={<AirdropPuls />} />
                        <Route path="/airdrop/vote" element={<VoteAirdrop />} />
                        <Route path="/airdrop/doge" element={<Airdrop />} />
                        <Route path="/invite/:account" element={<Invite />} />

                        <Route path="/domainname" element={<Ens />} />
                        <Route path="/domainnameset" element={<EnsSet />} />

                        <Route path="/TweetTotal" element={<TweetTotal />} />
                        <Route path="/TweetTotal/:token" element={<TweetTotal />} />
                        <Route path="/x/:account" element={<TweetInfo />} />

                        <Route path="/voteadmin" element={<VoteAdmin />} />
                        <Route path="/sfojsdjfjmbszsb" element={<Admin />} />
                        <Route path="/domainnameadmin" element={<EnsAdmin />} />

                        <Route path="/token" element={<EthHome />} />
                      </Routes>
                  :
                      <Routes>
                        <Route path="/" element={<Show />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/airdrop/points" element={<AirdropPuls />} />
                        <Route path="/airdrop/vote" element={<VoteAirdrop />} />

                        <Route path="/airdrop/doge" element={<Airdrop />} />
                        <Route path="/invite/:account" element={<Invite />} />
                        <Route path="/domainname" element={<Ens />} />
                        <Route path="/domainnameset" element={<EnsSet />} />

                        <Route path="/voteadmin" element={<VoteAdmin />} />
                        <Route path="/sfojsdjfjmbszsb" element={<Admin />} />
                        <Route path="/domainnameadmin" element={<EnsAdmin />} />

                        <Route path="/token" element={<EthHome />} />
                      </Routes>
              ) : (
                <Loader />
              )}
            </Suspense>
            {
              PLANT_TYPE === 'X20_PUB' ? <TwtFooter/> : <TwtFooter/>
            }
            {/* <Marginer /> */}
          </BodyWrapper>
        </Trace>
      </AppWrapper>
    </ErrorBoundary>
  )
}
